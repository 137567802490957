<style lang="scss"></style>

<template>
  <div class="update-app-modal">


    <b-card>
      <div class="d-flex align-items-baseline">
        <h3 v-if="enterprise" class="">Edit Enterprise: {{ enterprise.enterprise_display_name }}</h3>
        <div class="ui-spacer" />
      </div>
    </b-card>

    <div v-if="enterprise" class="">
      <b-tabs content-class="my-0">
        <b-tab active>
          <template slot="title"><feather-icon icon="SettingsIcon" />General</template>
          <b-card>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(updateEnterprise)">
                <h3 class="border-bottom mb-1 pb-1">General</h3>
                <section class="row">
                  <div class="col-sm-6">
                    <div class="mb-1">
                      <label>Contact Email</label>
                      <validation-provider v-slot="validationContext" name="Contact Email" :rules="{ required: true }">
                        <b-form-input v-model="enterprise.contact_email" type="email"
                                      :state="getValidationState(validationContext)" placeholder="Enter email*"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <label>Enterprise Display Name</label>
                      <validation-provider v-slot="validationContext" name="Enterprise Display Name" :rules="{ required: true }">
                        <b-form-input v-model="enterprise.enterprise_display_name"
                                      :state="getValidationState(validationContext)" placeholder="Enter display name*"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <validation-provider v-slot="validationContext" name="Package" :rules="{ required: true }">
                        <label class="mr-sm-2" for="password-quality">Password Requirements</label>
                        <b-form-select id="auto-update-mode" v-model="enterprise.password_quality"
                                       :state="getValidationState(validationContext)"
                        >
                          <b-form-select-option value="ALPHANUMERIC">Alphanumeric</b-form-select-option>
                          <b-form-select-option value="NONE">None</b-form-select-option>
                          <b-form-select-option value="SOMETHING">Something</b-form-select-option>
                          <b-form-select-option value="NUMERIC">Numeric</b-form-select-option>
                        </b-form-select>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <label>Owner device limit (0 = Unlimited)</label>
                      <validation-provider v-slot="validationContext" name="Owner device limit" :rules="{ required: true, min_value:0 }" :custom-messages="{min_value: 'The device limit must be positive or 0 for infinite'}">
                        <b-form-input v-model="enterprise.owner_device_limit" type="number"
                                      :state="getValidationState(validationContext)" placeholder="Enter the device limit" :number="true"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <label>Minimum password length for owners</label>
                      <validation-provider v-slot="validationContext" name="Owner minimum password length" :rules="{ required: true, min_value:6 }" :custom-messages="{min_value: 'The password length must be at least 6 characters long'}">
                        <b-form-input v-model="enterprise.owner_password_length" type="number"
                                      :state="getValidationState(validationContext)" placeholder="Enter the minimum password length" :number="true"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                  </div>

                  <div class="col-sm-6">

                    <div class="mb-1">
                      <validation-provider v-slot="validationContext" name="Mdm Version" :rules="{ required: true }">
                        <label class="mr-sm-2" for="mdm-version">MDM version</label>
                        <b-form-select id="auto-update-mode" v-model="enterprise.mdm_version"
                                       :state="getValidationState(validationContext)"
                        >
                          <b-form-select-option value="1">Version 1</b-form-select-option>
                          <b-form-select-option value="2">Version 2</b-form-select-option>
                        </b-form-select>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <validation-provider v-slot="validationContext" name="Purple Play Video Access" :rules="{ required: true }">
                        <label class="mr-sm-2">Purple Play Video Access</label>
                        <b-form-select v-model="enterprise.purple_play_video_access"
                                       :state="getValidationState(validationContext)"
                                       @change="showUpdateVideoAccessModal"
                        >
                          <b-form-select-option value="BLACKLIST">Blacklist</b-form-select-option>
                          <b-form-select-option value="WHITELIST">Whitelist</b-form-select-option>
                        </b-form-select>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <label>Shopping Email Address</label>
                      <b-form-input v-model="enterprise.shopping_email" placeholder="Shopping Email Address" />
                    </div>

                    <div class="row mb-1">
                      <div class="col-6">
                        <b-form-checkbox v-model="enterprise.two_factor_enabled" name="check-button" switch>
                          Two Factor Enabled
                        </b-form-checkbox>
                        <b-form-checkbox v-model="enterprise.purple_account_enabled" name="check-button" switch>
                          Purple Account Enabled
                        </b-form-checkbox>
                        <b-form-checkbox v-model="enterprise.purple_wallet_enabled" name="check-button" switch>
                          Purple Wallet Enabled
                        </b-form-checkbox>
                        <b-form-checkbox v-model="enterprise.can_change_password" name="check-button" switch>
                          Owner Update Password Enabled
                        </b-form-checkbox>
                        <b-form-checkbox v-model="enterprise.tracker_processing_enabled" name="check-button" switch>
                          Tracker Processing Enabled
                        </b-form-checkbox>
                        <b-form-checkbox v-model="enterprise.forgot_password_enabled" name="check-button" switch>
                          Forgot Password Enabled
                        </b-form-checkbox>
                        <b-form-checkbox v-model="enterprise.device_owner_login_enabled" name="check-button" switch>
                          Device Owner Login Enabled
                          <b-button id="popover-button-device-login" variant="flat-primary" class="btn-icon p-0">
                            <feather-icon icon="InfoIcon" />
                          </b-button>
                        </b-form-checkbox>

                        <b-popover target="popover-button-device-login" triggers="focus" placement="top">
                          <template #title>
                            <span>Device Owner Login Enabled</span>
                          </template>
                          <span>This will enable the owner login form if the device is in the enrollment group</span>
                        </b-popover>
                      </div>
                      <div class="col-6">
                        <b-form-checkbox v-model="enterprise.owner_strong_password_required" name="check-button" switch>
                          Owner Strong Password Required
                        </b-form-checkbox>
                        <b-form-checkbox v-model="enterprise.user_strong_password_required" name="check-button" switch>
                          User Strong Password Required
                        </b-form-checkbox>
                        <b-form-checkbox v-model="enterprise.owner_password_list_check" name="check-button" switch>
                          Owner Common Password Check
                          <b-button id="popover-button-owner-password-check" variant="flat-primary" class="btn-icon p-0">
                            <feather-icon icon="InfoIcon" />
                          </b-button>
                        </b-form-checkbox>
                        <b-form-checkbox v-model="enterprise.user_password_list_check" name="check-button" switch>
                          User Common Password Check
                          <b-button id="popover-button-user-password-check" variant="flat-primary" class="btn-icon p-0">
                            <feather-icon icon="InfoIcon" />
                          </b-button>
                        </b-form-checkbox>

                        <b-popover target="popover-button-owner-password-check" triggers="focus" placement="top">
                          <template #title>
                            <span>Common Password Check</span>
                          </template>
                          <span>This will check the provided password against the list of commonly used passwords</span>
                        </b-popover>
                        <b-popover target="popover-button-user-password-check" triggers="focus" placement="top">
                          <template #title>
                            <span>Common Password Check</span>
                          </template>
                          <span>This will check the provided password against the list of commonly used passwords</span>
                        </b-popover>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <p class="font-weight-bolder">Data Protection Officer</p>
                    <div class="mb-1">
                      <label>Email</label>
                      <validation-provider v-slot="validationContext" name="Data Protect Officer Email"
                                           :rules="{ required: true }"
                      >
                        <b-form-input v-model="enterprise.data_protection_officer_email" type="email"
                                      :state="getValidationState(validationContext)" placeholder="Enter email*"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <label>Name</label>
                      <validation-provider v-slot="validationContext" name="Data Protect Officer Name"
                                           :rules="{ required: true }"
                      >
                        <b-form-input v-model="enterprise.data_protection_officer_name"
                                      :state="getValidationState(validationContext)" placeholder="Enter name*"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <label>Phone Number</label>
                      <validation-provider v-slot="validationContext" name="Data Protect Officer Phone"
                                           :rules="{ required: true }"
                      >
                        <b-form-input v-model="enterprise.data_protection_officer_phone"
                                      :state="getValidationState(validationContext)" placeholder="Enter phone number*"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <p class="font-weight-bolder">Eu Representative Officer</p>
                    <div class="mb-1">
                      <label>Email</label>
                      <validation-provider v-slot="validationContext" name="Representative Email" :rules="{ required: true }">
                        <b-form-input v-model="enterprise.eu_representative_email" type="email"
                                      :state="getValidationState(validationContext)" placeholder="Enter email*"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <label>Name</label>
                      <validation-provider v-slot="validationContext" name="Representative Name" :rules="{ required: true }">
                        <b-form-input v-model="enterprise.eu_representative_name"
                                      :state="getValidationState(validationContext)" placeholder="Enter name*"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <label>Phone Number</label>
                      <validation-provider v-slot="validationContext" name="Representative Phone" :rules="{ required: true }">
                        <b-form-input v-model="enterprise.eu_representative_phone"
                                      :state="getValidationState(validationContext)" placeholder="Enter phone number*"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </div>
                </section>
                <section class="col-sm-12 p-0">
                  <h3 class="border-bottom mb-1 mt-2 pb-1">Optional Security</h3>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="mb-1">
                        <label>User IP ranges using CIDR notation e.g 80.80.102.44/32. </label>
                        <b-form-input v-model="enterprise.user_ip_ranges" placeholder="e.g. 0.0.0.0/32,127.0.0.0/30" />
                      </div>
                      <div class="mb-1">
                        <label>Refresh token duration (minutes)</label>
                        <validation-provider v-slot="validationContext" name="Refresh token duration" :rules="{ required: true, min_value:0 }" :custom-messages="{min_value: 'Duration must be a positive'}">
                          <b-form-input v-model="enterprise.refresh_token_duration" type="number"
                                        :state="getValidationState(validationContext)" placeholder="Enter duration in minutes" :number="true"
                          />

                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mb-1">
                        <label>Owner IP ranges using CIDR notation e.g 80.80.102.44/32.
                        </label>
                        <b-form-input v-model="enterprise.owner_ip_ranges" placeholder="e.g. 0.0.0.0/32,127.0.0.0/30" />
                      </div>
                      <div class="mb-1">
                        <validation-provider v-slot="validationContext" name="Allow scribe audio download" :rules="{ required: true }">
                          <label class="mr-sm-2">Allow scribe audio download</label>
                          <b-form-select v-model="enterprise.allow_scribe_audio_download"
                                         :state="getValidationState(validationContext)"
                          >
                            <b-form-select-option :value="true">Yes</b-form-select-option>
                            <b-form-select-option :value="false">No</b-form-select-option>
                          </b-form-select>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="col-sm-12 p-0">
                  <h3 class="border-bottom mb-1 mt-2 pb-1">Possessions</h3>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="mb-1">
                        <label>Possession Request Message</label>
                        <b-form-textarea
                          v-model="enterprise.possession_request_message"
                          placeholder="Enter a possession request message..."
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section class="col-sm-12 p-0">
                  <h3 class="border-bottom mb-1 mt-2 pb-1">Data Retention</h3>
                  <div class="row">
                    <div class="col sm-6">
                      <div class="mb-1">
                        <label>Scribe Audio File Retention (Days)</label>
                        <validation-provider v-slot="validationContext" name="Scribe Audio File Retention (Days)" :rules="{ required: true, min_value:0 }" :custom-messages="{min_value: 'Audio file retention must be positive'}">
                          <b-form-input v-model="enterprise.scribe_audio_retention_days" type="number" min="0"
                                        :state="getValidationState(validationContext)" placeholder="Duration to retain scribe audio files..." :number="true"
                          />

                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col sm-6">
                      <div class="mb-1">
                        <label>Scribe Transcript Retention (Days)</label>
                        <validation-provider v-slot="validationContext" name="Scribe Transcript Retention (Days)" :rules="{ required: true, min_value:0 }" :custom-messages="{min_value: 'Transcript retention must be positive'}">
                          <b-form-input v-model="enterprise.scribe_transcript_retention_days" type="number" min="0"
                                        :state="getValidationState(validationContext)" placeholder="Duration to retain scribe transcripts..." :number="true"
                          />

                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col sm-6">
                      <div class="mb-1">
                        <label>Scribe Summary Retention (Days)</label>
                        <validation-provider v-slot="validationContext" name="Scribe Summary Retention (Days)" :rules="{ required: true, min_value:0 }" :custom-messages="{min_value: 'Summary retention must be positive'}">
                          <b-form-input v-model="enterprise.scribe_summary_retention_days" type="number" min="0"
                                        :state="getValidationState(validationContext)" placeholder="Duration to retain scribe summaries..." :number="true"
                          />

                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col-sm-6" />
                  </div>
                </section>
                <section class="col-sm-12 p-0">
                  <h3 class="border-bottom mb-1 mt-2 pb-1">TheHub</h3>
                  <div class="row">
                    <div class="col sm-6">
                      <b-form-checkbox v-model="enterprise.allow_the_hub_likes" name="check-button" switch>
                        Allow Likes
                      </b-form-checkbox>
                      <b-form-checkbox v-model="enterprise.allow_the_hub_comments" name="check-button" switch>
                        Allow Comments
                      </b-form-checkbox>
                    </div>
                  </div>
                </section>
                <div class="col text-right">
                  <b-button v-if="enterprise.uid" class="btn btn-warning mr-1 mt-1" tag="button" :to="{ name: 'enterprise-view', params: { uid: enterprise.uid, enterprise: enterprise } }">
                    Cancel
                  </b-button>
                  <b-button type="submit" variant="primary" class="mt-1">Update Enterprise</b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
        <b-tab>
          <template slot="title"><feather-icon icon="ServerIcon" />Proxy</template>
          <b-card>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(updateEnterpriseProxy)">
                <section class="col-sm-12 p-0">
                  <h3 class="border-bottom mb-1 pb-1">Proxy</h3>
                  <div class="row">
                    <div class="col-sm-6">
                      <b-form-checkbox v-model="enterprise_proxy.enabled" name="check-button" switch>
                        Proxy Enabled
                      </b-form-checkbox>
                      <div class="mt-1">
                        <label>Whitelisted enterprise IP ranges using CIDR notation e.g 80.80.102.44/32. </label>
                        <b-form-input v-model="enterprise_proxy.allowed_ip_addresses" placeholder="e.g. 0.0.0.0/32,127.0.0.0/30" />
                      </div>
                    </div>
                  </div>
                </section>
                <div class="col text-right">
                  <b-button v-if="enterprise.uid" class="btn btn-warning mr-1 mt-1" tag="button" :to="{ name: 'enterprise-view', params: { uid: enterprise.uid, enterprise: enterprise } }">
                    Cancel
                  </b-button>
                  <b-button type="submit" variant="primary" class="mt-1">Update Proxy</b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
      </b-tabs>
    </div>
    <section v-else class="row mb-2">
      <div class="col-12">
        <p>No Enterprise found, please refresh and try again</p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line no-unused-vars
import EnterpriseService from '../../../../services/EnterpriseService'

export default {
  components: {},
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      initialVideoAccessSetting: 'BLACKLIST',
      enterprise: {
        contact_email: '',
        data_protection_officer_email: '',
        data_protection_officer_name: '',
        data_protection_officer_phone: '',
        enterprise_display_name: '',
        eu_representative_email: '',
        eu_representative_name: '',
        eu_representative_phone: '',
        password_quality: 'ALPHANUMERIC',
        purple_account_enabled: true,
        purple_wallet_enabled: true,
        purple_play_video_access: 'BLACKLIST',
        two_factor_enabled: true,
        can_change_password: false,
        forgot_password_enabled: false,
        shopping_email: '',
        mdm_version: 0,
        user_ip_ranges: '',
        owner_ip_ranges: '',
        refresh_token_duration: 0,
        owner_device_limit: 0,
        scribe_audio_retention_days: 30,
        scribe_transcript_retention_days: 90,
        scribe_summary_retention_days: 90,
        allow_scribe_audio_download: false,
        allow_the_hub_likes: false,
        allow_the_hub_comments: false,
      },
      enterprise_proxy: {
        enabled: false,
        allowed_ip_addresses: "",
      },
    }
  },
  computed: {
    ...mapState({
      enterpriseExisting: state => state.enterprise.enterprise,
      user: state => state.user.currentUser,
    }),
  },
  async mounted() {
    await this.getEnterprise();
    await this.getEnterpriseProxy();
    this.loading = false;
  },
  methods: {
    async getEnterprise() {
      await this.$store.dispatch('enterprise/getEnterprise', this.uid)

      if (this.enterpriseExisting) {
        this.enterprise = {
          ...this.enterpriseExisting,
          enterprise_display_name: this.enterpriseExisting.display_name,
        }
        // Set initial video access setting
        this.initialVideoAccessSetting = this.enterprise.purple_play_video_access
      }
    },
    async getEnterpriseProxy() {
      EnterpriseService.getEnterpriseProxy(this.uid).then(res => {
        this.enterprise_proxy = res.data;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get enterprise proxy, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    updateEnterprise() {
      // make sure mdm version is an integer
      this.enterprise.mdm_version = parseInt(this.enterprise.mdm_version, 10)

      EnterpriseService.updateEnterprise(this.enterpriseExisting.uid, this.enterprise)
        .then(() => {
          this.$toast.success(`Updated enterprise ${this.enterprise.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })

          // update Current user data
          this.user.enterprise_wallet_enabled = this.enterprise.purple_wallet_enabled
          this.$store.commit('user/SET_CURRENT_USER', this.user)

          this.$emit('refresh', true)
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not update enterprise, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    updateEnterpriseProxy() {
      EnterpriseService.updateEnterpriseProxy(this.enterpriseExisting.uid, this.enterprise_proxy)
        .then(() => {
          this.$toast.success(`Updated enterprise proxy successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })
          this.$emit('refresh', true)
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not update enterprise proxy, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    getValidationState({
                         dirty,
                         validated,
                         valid = null,
                       }) {
      return dirty || validated ? valid : null
    },
    showUpdateVideoAccessModal() {
      if(this.initialVideoAccessSetting === this.enterprise.purple_play_video_access) {
        return;
      }
      this.$bvModal.msgBoxConfirm(`Warning: Updating the Purple Play Video Access setting for this enterprise from ${this.initialVideoAccessSetting} to ${this.enterprise.purple_play_video_access} will clear ALL existing video access records. Please make sure you are certain before clicking update enterprise.`, {
          title: 'Update Purple Play Video Access',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
          okTitle: "Keep change",
          cancelTitle: "Revert change",
        }
      ).then(val => {
        if(!val) {
          this.enterprise.purple_play_video_access = this.initialVideoAccessSetting
        }
      })
    },
  },
}
</script>
